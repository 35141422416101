import React from "react";
import KakaoMap from "../../components/map/KakaoMap";

const MapPage = () => {
  return (
    <>
      <KakaoMap />
    </>
  );
};

export default MapPage;
